<template>
  <div>
    <b-card>
      <progress-bar-status :progress="2" :status="aktivasiHB.status" />
    </b-card>
    <div class="bg-white p-5 rounded mt-3">
      <div class="d-flex align-items-start justify-content-between">
        <h2>Hasil Pemeriksaan Oleh HB</h2>
        <div>
          <b-button v-if="!isEditing" @click="isEditing = true">Edit</b-button>
          <b-button v-if="isEditing" variant="danger" @click="cancelEdit"
            >Batal</b-button
          >
          <b-button
            v-if="isEditing"
            class="ml-1"
            type="submit"
            variant="success"
            @click="saveChanges"
            >Simpan</b-button
          >
        </div>
      </div>
      <b-row>
        <b-col>
          <b-form>
            <b-form-group label="Status">
              <b-form-checkbox v-model="isDone" :disabled="!isEditing" switch>
                {{ isDone ? "Diterima" : "Ditolak" }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="Metode HB Meter">
              <b-form-input
                v-model="aktivasiHB.hb_meter"
                :disabled="!isEditing"
              ></b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Berat Jenis 1.053">
                  <b-form-radio-group
                    v-model="aktivasiHB.kupper_sulfat"
                    :disabled="!isEditing"
                  >
                    <b-form-radio value="> 12,5 gr% Tenggelam"
                      >> 12,5 gr% Tenggelam</b-form-radio
                    >
                    <b-form-radio value="= 12,5 gr% Melayang"
                      >= 12,5 gr% Melayang</b-form-radio
                    >
                    <b-form-radio value="< 12,5 gr% Mengapung"
                      >< 12,5 gr% Mengapung</b-form-radio
                    >
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Berat Jenis 1.062">
                  <b-form-radio-group
                    v-model="aktivasiHB.kupper_sulfat"
                    :disabled="!isEditing"
                  >
                    <b-form-radio value="< 17 gr% Mengapung"
                      >< 17 gr% Mengapung</b-form-radio
                    >
                    <b-form-radio value="= 17 gr% Melayang"
                      >= 17 gr% Melayang</b-form-radio
                    >
                    <b-form-radio value="> 17 gr% Tenggelam"
                      >> 17 gr% Tenggelam</b-form-radio
                    >
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Golongan Darah">
              <b-form-select v-model="selectedBloodType" :disabled="!isEditing">
                <b-form-select-option
                  v-for="bloodType in bloodTypes"
                  :key="bloodType.id"
                  :value="bloodType.type"
                >
                  {{ bloodType.type }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Alasan Penolakan" v-if="isDone === false">
              <b-form-select
                v-model="aktivasiHB.reason_rejected"
                :disabled="!isEditing"
              >
                <b-form-select-option value="hb tinggi"
                  >HB Tinggi</b-form-select-option
                >
                <b-form-select-option value="hb rendah"
                  >HB Rendah</b-form-select-option
                >
                <b-form-select-option value="lain-lain"
                  >Lain-lain</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Catatan Penolakan"
              v-if="
                isDone === false && aktivasiHB.reason_rejected === 'lain-lain'
              "
            >
              <b-form-textarea
                v-model="aktivasiHB.note"
                :disabled="!isEditing"
              ></b-form-textarea>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ProgressBarStatus from "@/view/main/dashboards/registrationdonation/components/common/ProgressBarStatus.vue";
import manageHbAPI from "../../../../../../api/manageHB/manageHbAPI";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormRadioGroup,
  BFormRadio,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  name: "DetailHB",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BFormCheckbox,
    ProgressBarStatus,
  },
  data() {
    return {
      aktivasiHB: {},
      isEditing: false,
      originalData: {},
      selectedBloodType: "",
      isDone: false,
      bloodTypes: [
        { id: 1, type: "A+" },
        { id: 2, type: "A-" },
        { id: 3, type: "B+" },
        { id: 4, type: "B-" },
        { id: 5, type: "AB+" },
        { id: 6, type: "AB-" },
        { id: 7, type: "O+" },
        { id: 8, type: "O-" },
      ],
    };
  },
  mounted() {
    this.fetchDetailHB();
  },
  watch: {
    "aktivasiHB.blood_type": {
      handler(newVal) {
        const bloodType = this.bloodTypes.find((b) => b.type === newVal);
        this.selectedBloodType = bloodType ? bloodType.type : "";
      },
      immediate: true,
    },
  },
  methods: {
    async fetchDetailHB() {
      try {
        const response = await manageHbAPI.getDetail(this.$route.params.id);
        this.aktivasiHB = response.data.data;
        this.originalData = { ...this.aktivasiHB };
        this.selectedBloodType = this.aktivasiHB.blood_type;
        this.isDone =
          this.aktivasiHB.status === "Done" ||
          this.aktivasiHB.status === "Approved";
        console.log(this.aktivasiHB);
      } catch (error) {
        console.error("Error fetching HB details:", error);
      }
    },
    async saveChanges() {
      try {
        const selectedBlood = this.bloodTypes.find(
          (b) => b.type === this.selectedBloodType
        );
        const updatedData = {
          ...this.aktivasiHB,
          blood_id: selectedBlood.id,
          status: this.isDone ? "Done" : "Rejected", // Set status based on isDone
          reject_reason: this.isDone ? "" : this.aktivasiHB.reject_reason,
          note:
            this.aktivasiHB.reason_rejected !== "lain-lain"
              ? ""
              : this.aktivasiHB.note,
        };
        await manageHbAPI.edit(updatedData);
        this.isEditing = false;
        // Show success message toast
        this.$bvToast.toast("Perubahan berhasil disimpan", {
          title: "Sukses",
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error("Error saving changes:", error);
        // Show error message toast
        this.$bvToast.toast("Gagal menyimpan perubahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    cancelEdit() {
      this.aktivasiHB = { ...this.originalData };
      this.selectedBloodType = this.aktivasiHB.blood_type;
      this.isDone =
        this.aktivasiHB.status === "Done" ||
        this.aktivasiHB.status === "Approved";
      this.isEditing = false;
    },
  },
};
</script>

<style scoped>
label {
  margin: 0;
  color: #636e72;
}
</style>
