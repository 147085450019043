import { render, staticRenderFns } from "./DetailHB.vue?vue&type=template&id=3336b658&scoped=true"
import script from "./DetailHB.vue?vue&type=script&lang=js"
export * from "./DetailHB.vue?vue&type=script&lang=js"
import style0 from "./DetailHB.vue?vue&type=style&index=0&id=3336b658&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3336b658",
  null
  
)

export default component.exports